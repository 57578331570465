import { configureStore } from '@reduxjs/toolkit';
import autosuggestReducer from '../features/autosuggest/state';
import plpReducer from '../features/plp/state';
import searchReducer from '../features/search/state';

export const store = configureStore({
  reducer: {
    // keep-sorted start
    autosuggest: autosuggestReducer,
    plp: plpReducer,
    search: searchReducer,
    // keep-sorted end
  },
});
