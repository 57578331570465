import { getLocalePathPrefix } from './locale';

const toURL = (url) => (url instanceof URL ? url : new URL(url, window.location.href));

let websiteDomainRegexps = null;

/**
 * Check if a given domain is an MV website domain.
 *
 * @param {string} domain
 * @returns {boolean}
 */
const isWebsiteDomain = (domain) => {
  if (websiteDomainRegexps === null) {
    websiteDomainRegexps = window.mv?.website_domains?.map((regexp) => new RegExp(regexp)) ?? [];
  }

  return websiteDomainRegexps.some((regexp) => regexp.test(domain));
};

/**
 * Transform a URL for use in the current environment.
 *
 * URLs returned by third-party services usually point at an MV production or
 * staging site. This function will replace the hostname of the given URL with
 * the hostname of the current location, so we don't accidentally land on a
 * different site during development or testing.
 *
 * @param {URL | string} url
 * @returns {URL}
 */
export const urlForEnvironment = (url) => {
  const u = toURL(url);

  if (isWebsiteDomain(u.hostname)) {
    u.hostname = window.location.hostname;
  }

  return u;
};

/**
 * Add the locale path prefix to the given URL if not already present.
 *
 * @param {URL | string} url
 * @param {string | undefined} prefix Defaults to the current locale path prefix.
 * @returns {URL}
 */
export const addLocalePathPrefix = (url, prefix) => {
  if (prefix === undefined) {
    prefix = getLocalePathPrefix();
  }

  const u = toURL(url);
  const path = u.pathname;

  if (!path.startsWith(prefix)) {
    u.pathname = prefix + path;
  }

  return u;
};

/**
 * Fix a given URL for use in the current environment.
 *
 * Changes the hostname and adds the locale path prefix if not already present.
 *
 * @param {URL | string} url
 * @returns {URL}
 */
export const fixSiteURL = (url) => urlForEnvironment(addLocalePathPrefix(toURL(url)));

/**
 * Add cache params to a given URL.
 *
 * @param {URL | string} url
 * @returns {URL}
 */
export const addCacheParams = (url) => {
  const u = toURL(url);

  for (let param in window.cacheParams ?? {}) {
    u.searchParams.set(param, window.cacheParams[param]);
  }

  return u;
}
