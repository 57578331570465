import { html, nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map.js';
import { map } from 'lit-html/directives/map.js';
import { when } from 'lit-html/directives/when.js';
import { getCurrency, getPriceCatalogue, formatPrice, convertPriceForReporting } from '../../util/currency';
import { fixSiteURL } from '../../util/url';
import { facetPersonalisable } from './facets';

/**
 * Select prices relevant to the customer
 *
 * @param {object} result Constructor search result
 * @returns
 */
export const selectPrices = ({ data, currency, priceCatalogue, eventId }) => {
  let ret = {
    currency: currency,
    now: data['price_' + currency.toLowerCase() + '_' + priceCatalogue]
  };

  let giftSetOfferKey = 'event_offers_' + currency.toLowerCase() + '_' + priceCatalogue;

  const findBestOffer = (offers) => {
    const timestampNow = (+new Date() / 1000);
    return offers.sort((a, b) => b.discount - a.discount).find((elem) => {
      if (elem.event != eventId) {
        return false;
      }

      return timestampNow > elem.start && timestampNow < elem.end;
    });
  }

  if (data.event_offers) {
    let discount = findBestOffer([...data.event_offers]);

    if (discount) {
      ret.was = ret.now;
      ret.now -= ret.was * (discount.discount / 100);

      if (discount.badge) {
        ret.badge = discount.badge;
      }
    }
  } else if (giftSetOfferKey in data) {
    let badge = '',
        now = 0;

    data[giftSetOfferKey].forEach((item) => {
        let itemDiscount = findBestOffer([...item.discounts]);

        if (!itemDiscount) {
            now += item.price;
        } else {
            now += item.price * ((100 - itemDiscount.discount) / 100);
            if (itemDiscount.badge) {
                badge = itemDiscount.badge;
            }
        }
    });

    if (now != ret.now) {
        ret.was = ret.now;
        ret.now = now;
        ret.badge = badge;
    }
  }

  if (data.is_bundle) {
      ret.original = data['set_price_' + currency.toLowerCase() + '_' + priceCatalogue]
      ret.saving = ret.original - ret.now;

      if (ret.was) {
        // This a bundle with active event offers so we recalculate the discount on top of the bundle discount
        let bundleSaving = ret.original - ret.was;
        ret.was = ret.original;
        ret.now -= bundleSaving * (ret.now / ret.was);
        delete ret.original;
        let bundlePrice = data['price_' + currency.toLowerCase() + '_' + priceCatalogue];
        let bundleDiscount = ret.now/bundlePrice;
        ret.saving = (bundleSaving * bundleDiscount);
      }
  }

  if (ret.was && ret.was != ret.now) {
    ret.discount = Math.round((ret.was - ret.now + Number.EPSILON) * 100) / 100;
    ret.discountPercentage = Math.round((((ret.was - ret.now) / ret.was) + Number.EPSILON) * 100);
  }

  return ret;
}

const swatch = (metal) => html`
  <span class="swatch__display">
    <div class=${classMap({
      'filter-options__material': true,
      [`metal-swatch--${metal.class}`]: !!metal.class,
    })}></div>
    ${when(
      metal.image_url,
      () => html`
        <img src="${metal.image_url}">
      `,
      () => html`
        <i class=${classMap({
            'metal-swatch': true,
            [`metal-swatch--${metal.class}`]: true,
          })}
        ></i>
      `,
    )}
  </span>
`;

/**
 * Return a lit-html template that renders a product result.
 *
 * @param {*} data
 * @returns {TemplateResult}
 */
export const resultTemplate = ({ result, locale, eventId, selectedVariation, setSelectedVariation, lazy, onClick, position }) => {
  const variationData = result.variations?.find((variation) => variation.data.variation_id === selectedVariation)?.data;
  const data = { ...result.data, ...variationData };

  const currency = getCurrency();
  const priceCatalogue = getPriceCatalogue();

  const prices = selectPrices({ data, currency, priceCatalogue, eventId });

  const badge = prices.badge ?? data.badge ?? false;

  const personalisable = data.facets?.find((facet) => facet.name === facetPersonalisable)?.values?.includes('Yes');

  const variationMetals = [];
  const filteredVariations = [];
  const originalVariation = result.data.variation_id;
  const variations = result.variations ?? [];
  const sortedVariations = [...variations].sort((a, b) => {
      let aMatches = a.data.variation_id == originalVariation;
      let bMatches = b.data.variation_id == originalVariation;
      if (aMatches > bMatches) {
        return -1;
      } else if (bMatches > aMatches) {
        return 1;
      }
      return 0;
  });

  for (const variation of sortedVariations) {
    const metalId = variation.data.primary_metal?.id;
    if (!metalId || variationMetals.includes(metalId)) {
      continue;
    }

    if (metalId !== variationData.primary_metal?.id || variation.data.variation_id === selectedVariation) {
      variationMetals.push(metalId);
      filteredVariations.push(variation);
    }
  }

  filteredVariations.sort((a, b) => {
    return a.data.primary_metal?.id > b.data.primary_metal?.id ? -1 : 1;
  });

  return html`
    <article class="product-preview product-preview--constructor" tabindex="0">
      <a
        href="${fixSiteURL(data.url)}"
        class="product-preview__link js-gtm-element"
        data-gatype="product-card"
        data-galabel="${data.ga_label}"
        data-gaid="${data.code}"
        data-brand="Monica Vinader"
        ${when(
            prices.was,
            () => html`
                data-price="${prices.was}"
                data-discount="${prices.was - prices.now}"
            `,
            () => html`
                data-price="${prices.now}"
            `,
        )}
        data-cnstrc-item-section="Products"
        data-cnstrc-item-name="${result.value}"
        data-cnstrc-item-id="${data.id}"
        data-cnstrc-item-variation-id="${data.variation_id}"
        data-cnstrc-item-price="${convertPriceForReporting(prices.now)}"
        @click=${(e) => {
            if (e.target.nodeName === "BUTTON") {
                // Swatch selector
                return;
            }

            onClick(e);
        }}
      >
        <div class="product-preview__images product-preview__images--constructor">
          ${when(data.image_url, () => html`
            <figure
              class=${classMap({
                'product-preview__image': true,
                'product-preview__image--no-blend': !data.image_grey_blend,
              })}
            >
              <img
                src="${data.image_url}"
                width="588"
                height="648"
                loading="${lazy === false ? nothing : 'lazy'}"
                class="product-listing__image"
                >
                <ul class="product-preview__image-indicators">
                    <li class="product-preview__image-indicator product-preview__image-indicator--active"></li>
                    <li class="product-preview__image-indicator"></li>
                </ul>
            </figure>
          `)}
          ${when(data.hover_image_url, () => html`
            <figure class="product-preview__image product-preview__image--hover">
              <img
                src="${data.hover_image_url}"
                width="588"
                height="648"
                loading="lazy"
                class="product-listing__image product-listing__image--under-image"
                >
                <ul class="product-preview__image-indicators">
                    <li class="product-preview__image-indicator"></li>
                    <li class="product-preview__image-indicator product-preview__image-indicator--active"></li>
                </ul>
            </figure>
          `)}
        </div>
        <header class="product-preview__content">
          <h3 class="product-preview__title">
            ${data.short_title}
          </h3>
          <div class="product-preview__swatch">
            <div class="swatch-group">
              <div class="swatch-group__options">
                ${when(
                  filteredVariations.length,
                  () => html`
                    ${map(filteredVariations, ({ data: variationData }) => html`
                      <button
                        type="button"
                        class="${classMap({
                          'swatch': true,
                          'swatch--active': variationData.variation_id === selectedVariation,
                        })}"
                        aria-label="${variationData.variation_title}"
                        @click=${(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedVariation({ item: result.data.variation_id, variation: variationData.variation_id });
                        }}
                      >
                        ${variationData.primary_metal ? swatch(variationData.primary_metal) : nothing}
                      </button>
                    `)}
                  `,
                  () => html`
                    <div class="swatch swatch--active swatch--readonly">
                      ${data.primary_metal ? swatch(data.primary_metal) : nothing}
                    </div>
                  `,
                )}
              </div>
            </div>
          </div>
          <p class="product-preview__description">
            ${data.variation_title}
          </p>
          ${when(
            data.is_bundle && !prices.was,
            () => html`
              <p class="product-preview__price product-preview__price--bundle">
                    ${formatPrice({ price: prices.now, locale, currency })}
                    <span>${formatPrice({ price: prices.original, locale, currency })}</span>
                </p>
            `,
            () =>
              when(
                prices.was,
                () => html`
                  <p class="product-preview__price product-preview__price--was">
                    <span>${formatPrice({ price: prices.was, locale, currency })}</span>

                    ${formatPrice({ price: prices.now, locale, currency })}

                    ${when(prices.discountPercentage, () => html`
                      <span class="product-preview__price--discount">
                        ${prices.discountPercentage}% off
                      </span>
                    `)}
                  </p>
                `,
                () => html`
                  <p class="product-preview__price">
                    ${formatPrice({ price: prices.now, locale, currency })}
                  </p>
                `,
              )
            )}
            <button class="product-preview__quickadd-button js-quick-atb"
                    role="button"
                    aria-label="Add to Bag"
                    data-cnstrc-btn="add_to_cart"
                    @click=${ (e) => {
                        MV.tracking.setupQuickadd( e,
                            window.location.pathname.replace(new RegExp('^' + window.localePathPrefix + '/'), '/'),
                            { position }
                        )
                        MV.quickaddClick(e);
                    } }
            >
              <img class="product-preview__quickadd-button-icon" src="/images/2020/bag-add.svg" alt="Bag Icon" width="18" height="18" />
            </button>
        </header>

        ${when(personalisable, () => html`
          <div class="product-preview__engravable">
            <img src="/images/2020/personalisation-black.svg" width="14px" height="16px" alt="Personalisable">
          </div>
        `)}

         ${when(data.show_bundle_badge,
            () => html`
              <div class="flash-badge js-flash-badge flash-badge--listing">
                <span>Set | Save ${formatPrice({ price: prices.saving, locale, currency })}</span>
              </div>
            `,
            () => when(badge, () => html`
              <div class="flash-badge js-flash-badge flash-badge--listing">
                <span>${badge}</span>
              </div>
            `)
          )}
      </a>
    </article>
  `;
};

/**
 * Return a lit-html template that renders a product result placeholder.
 *
 * This is intended to take up the same amount of space as a real product
 * result, to avoid a layout shift when the results have loaded. To achieve
 * that, it has roughly the same DOM structure. It's not perfect, because a
 * layout shift can still happen if a real product result has text that flows
 * onto multiple lines, but it's good enough.
 *
 * @returns {TemplateResult}
 */
export const resultPlaceholderTemplate = () => html`
  <div class="product-preview product-preview--placeholder">
    <div class="product-preview__link">
      <div class="product-preview__images product-preview__images--constructor">
        <div class="product-preview__image"></div>
      </div>
      <div class="product-preview__content">
        <div class="product-preview__title">&nbsp;</div>
        <div class="product-preview__swatch swatch swatch--readonly">
          <div class="swatch-group">
            <div class="swatch-group__options">
              <div class="swatch">
                <div class="swatch__display">
                  <div class="filter-options__material"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-preview__description">&nbsp;</div>
        <div class="product-preview__price">&nbsp;</div>
      </div>
    </div>
  </div>
`;
