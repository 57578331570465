import { LitElement, css, html } from 'lit';
import { when } from 'lit-html/directives/when.js';
import { map } from 'lit-html/directives/map.js';
import { classMap } from 'lit-html/directives/class-map.js';
import noUiSlider from 'nouislider';
// import  from 'nouislider/dist/nouislider.css';

/**
 * Carousel element.
 *
 * This class implements the mv-carousel element. It is a container for a set of
 * slides. When the slide count or slide visibility changes, a
 * slide-count-changed is dispatched event so that other elements can update
 * their state.
 *
 * The mv-carousel-button element is built to work with this component.
 */
export default class Slider extends LitElement {
  static properties = {
    min: {
      type: Number,
      attribute: true,
    },
    max: {
      type: Number,
      attribute: true,
    },
    lower: {
      type: Number,
      attribute: true,
    },
    upper: {
      type: Number,
      attribute: true,
    },
    margin: {
      type: Number,
      attribute: true,
    },
    connect: {
      type: Boolean,
      attribute: true,
    },
    disabled: {
      type: Boolean,
      attribute: true,
    },
    valuePosition: {
      type: String,
      attribute: true,
    },
    values: {
      type: Array,
      property: true,
    },
    positions: {
      type: Array,
      property: true,
    },
    valuePositionOverrides: {
      type: Array,
      property: true,
    },
  }

  static styles = css`
    .values {
      position: relative;
      width: 100%;
      height: 20px;
    }

    .values .value {
      position: absolute; 
      transform: translateX(-50%);
      // -webkit-transition: left 0.3s;
      // transition: left 0.3s;
    }

    .values .value--hyphenated:after {
      content: '-';
      position: absolute;
    }

    .slider.noUi-horizontal {
      height: 4px;
      border-radius: 3px;
      border: none;
      box-shadow: 0 0 0 1px #B1B1B1;
    }

    .slider.noUi-horizontal .noUi-handle {
      width: 17px;
      height: 17px;
      top: -7px;
      right: -11px;
    }

    .slider .noUi-target {
      background: #f1eee4;
      border: none;
      box-shadow: none;
    }

    .slider .noUi-connects {
      height: 6px;
      top: -1px;
    }

    .slider .noUi-connect {
      background: $c-black;
      background: black;
      box-shadow: 0 0 0 1px black;
    }

    .slider .noUi-handle {
      border-radius: 50%;
      box-shadow: none;
      background: $c-white;
      background: white;
      border: 1px solid $c-black;
      border: 1px solid black;
      width: 28px;
      top: -8px;
    }

    .slider .noUi-handle:after {
      display: none;
    }

    .slider .noUi-handle:before {
      content: '';
      background: black;
      width: 9px;
      height: 9px;
      border-radius: 100%;
      position: absolute;
      left: 3px;
      top: 3px;
    }

    /* Functional styling;
     * These styles are required for noUiSlider to function.
     * You don't need to change these rules to apply your design.
     */
    .noUi-target,
    .noUi-target * {
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-user-select: none;
      -ms-touch-action: none;
      touch-action: none;
      -ms-user-select: none;
      -moz-user-select: none;
      user-select: none;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    .noUi-target {
      position: relative;
    }
    .noUi-base,
    .noUi-connects {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
    /* Wrapper for all connect elements.
     */
    .noUi-connects {
      overflow: hidden;
      z-index: 0;
    }
    .noUi-connect,
    .noUi-origin {
      will-change: transform;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      -ms-transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      -webkit-transform-style: preserve-3d;
      transform-origin: 0 0;
      transform-style: flat;
    }
    /* Offset direction
     */
    .noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
      left: 0;
      right: auto;
    }
    /* Give origins 0 height/width so they don't interfere with clicking the
     * connect elements.
     */
    .noUi-vertical .noUi-origin {
      top: -100%;
      width: 0;
    }
    .noUi-horizontal .noUi-origin {
      height: 0;
    }
    .noUi-handle {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
    }
    .noUi-touch-area {
      height: 100%;
      width: 100%;
    }
    .noUi-state-tap .noUi-connect,
    .noUi-state-tap .noUi-origin {
      -webkit-transition: transform 0.3s;
      transition: transform 0.3s;
    }
    .noUi-state-drag * {
      cursor: inherit !important;
    }
    /* Slider size and handle placement;
     */
    .noUi-horizontal {
      height: 18px;
    }
    .noUi-horizontal .noUi-handle {
      width: 34px;
      height: 28px;
      right: -17px;
      top: -6px;
    }
    .noUi-vertical {
      width: 18px;
    }
    .noUi-vertical .noUi-handle {
      width: 28px;
      height: 34px;
      right: -6px;
      bottom: -17px;
    }
    .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
      left: -17px;
      right: auto;
    }
    /* Styling;
     * Giving the connect element a border radius causes issues with using transform: scale
     */
    .noUi-target {
      background: #FAFAFA;
      border-radius: 4px;
      border: 1px solid #D3D3D3;
      box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
    }
    .noUi-connects {
      border-radius: 3px;
    }
    .noUi-connect {
      background: #3FB8AF;
    }
    /* Handles and cursors;
     */
    .noUi-draggable {
      cursor: ew-resize;
    }
    .noUi-vertical .noUi-draggable {
      cursor: ns-resize;
    }
    .noUi-handle {
      border: 1px solid #D9D9D9;
      border-radius: 3px;
      background: #FFF;
      cursor: default;
      box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
    }
    .noUi-active {
      box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
    }
    /* Handle stripes;
     */
    .noUi-handle:before,
    .noUi-handle:after {
      content: "";
      display: block;
      position: absolute;
      height: 14px;
      width: 1px;
      background: #E8E7E6;
      left: 14px;
      top: 6px;
    }
    .noUi-handle:after {
      left: 17px;
    }
    .noUi-vertical .noUi-handle:before,
    .noUi-vertical .noUi-handle:after {
      width: 14px;
      height: 1px;
      left: 6px;
      top: 14px;
    }
    .noUi-vertical .noUi-handle:after {
      top: 17px;
    }
    /* Disabled state;
     */
    [disabled] .noUi-connect {
      background: #B8B8B8;
    }
    [disabled].noUi-target,
    [disabled].noUi-handle,
    [disabled] .noUi-handle {
      cursor: not-allowed;
    }
    /* Base;
     *
     */
    .noUi-pips,
    .noUi-pips * {
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    .noUi-pips {
      position: absolute;
      color: #999;
    }
    /* Values;
     *
     */
    .noUi-value {
      position: absolute;
      white-space: nowrap;
      text-align: center;
    }
    .noUi-value-sub {
      color: #ccc;
      font-size: 10px;
    }
    /* Markings;
     *
     */
    .noUi-marker {
      position: absolute;
      background: #CCC;
    }
    .noUi-marker-sub {
      background: #AAA;
    }
    .noUi-marker-large {
      background: #AAA;
    }
    /* Horizontal layout;
     *
     */
    .noUi-pips-horizontal {
      padding: 10px 0;
      height: 80px;
      top: 100%;
      left: 0;
      width: 100%;
    }
    .noUi-value-horizontal {
      -webkit-transform: translate(-50%, 50%);
      transform: translate(-50%, 50%);
    }
    .noUi-rtl .noUi-value-horizontal {
      -webkit-transform: translate(50%, 50%);
      transform: translate(50%, 50%);
    }
    .noUi-marker-horizontal.noUi-marker {
      margin-left: -1px;
      width: 2px;
      height: 5px;
    }
    .noUi-marker-horizontal.noUi-marker-sub {
      height: 10px;
    }
    .noUi-marker-horizontal.noUi-marker-large {
      height: 15px;
    }
    /* Vertical layout;
     *
     */
    .noUi-pips-vertical {
      padding: 0 10px;
      height: 100%;
      top: 0;
      left: 100%;
    }
    .noUi-value-vertical {
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      padding-left: 25px;
    }
    .noUi-rtl .noUi-value-vertical {
      -webkit-transform: translate(0, 50%);
      transform: translate(0, 50%);
    }
    .noUi-marker-vertical.noUi-marker {
      width: 5px;
      height: 2px;
      margin-top: -1px;
    }
    .noUi-marker-vertical.noUi-marker-sub {
      width: 10px;
    }
    .noUi-marker-vertical.noUi-marker-large {
      width: 15px;
    }
    .noUi-tooltip {
      display: block;
      position: absolute;
      border: 1px solid #D9D9D9;
      border-radius: 3px;
      background: #fff;
      color: #000;
      padding: 5px;
      text-align: center;
      white-space: nowrap;
    }
    .noUi-horizontal .noUi-tooltip {
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      left: 50%;
      bottom: 120%;
    }
    .noUi-vertical .noUi-tooltip {
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      top: 50%;
      right: 120%;
    }
    .noUi-horizontal .noUi-origin > .noUi-tooltip {
      -webkit-transform: translate(50%, 0);
      transform: translate(50%, 0);
      left: auto;
      bottom: 10px;
    }
    .noUi-vertical .noUi-origin > .noUi-tooltip {
      -webkit-transform: translate(0, -18px);
      transform: translate(0, -18px);
      top: auto;
      right: 28px;
    }
  `;

  constructor() {
    super();

    this.values = [];
    this.positions = [];
    this.valuePositionOverrides = [];
  }

  get #slider() {
    return this.shadowRoot?.querySelector('.slider');
  }

  adjustValuePositions() {
    const valuesContainer = this.shadowRoot?.querySelector('.values');
    if (!valuesContainer) {
      return;
    }

    const { positions } = this;
    const valuePositionOverrides = [];

    const valuesStart = valuesContainer.getBoundingClientRect().x;
    const valuesEnd = valuesContainer.getBoundingClientRect().right;
    const valuesW = valuesEnd - valuesStart;
    const margin = 8;

    for (const [i, position] of positions.entries()) {
      const valueEl = this.shadowRoot.querySelector(`.handle-${i}__value`);
      const prevValueEl = this.shadowRoot.querySelector(`.handle-${i - 1}__value`);

      if (valueEl && prevValueEl) {
        const valueElW = valueEl.getBoundingClientRect().width;
        const prevValueElW = prevValueEl.getBoundingClientRect().width;
        const positionInPx = valuePositionOverrides[i] ?? (positions[i] / 100) * valuesW;
        const prevPositionInPx = valuePositionOverrides[i - 1] ?? (positions[i - 1] / 100) * valuesW;

        const distanceBetweenHandleValueMiddles = ((prevValueElW + valueElW) / 2) + margin;

        // 8 is the weird case when the handles dont exist, and causes a bug on reopen
        if (positionInPx - prevPositionInPx < distanceBetweenHandleValueMiddles && distanceBetweenHandleValueMiddles > 8) {
          if (valuePositionOverrides[i - 1]) {
            valuePositionOverrides[i] = valuePositionOverrides[i - 1] + prevValueElW + margin;
          } else {
            const middle = positions[i - 1] + ((positions[i] - positions[i - 1]) / 2);
            const middleInPx = (middle / 100) * valuesW;
            let newPrevPos = middleInPx - ((prevValueElW + margin) / 2);
            let newPos = middleInPx + ((valueElW + margin) / 2);

            if (middleInPx < (prevValueElW + margin) / 2) {
              const adjustment = ((prevValueElW + margin) / 2) - middleInPx;
              newPrevPos += adjustment;
              newPos += adjustment;
            }

            if (middleInPx > (valuesW - (valueElW + margin) / 2) - margin) {
              const adjustment = (valuesW - (valueElW + margin) / 2) - middleInPx;
              newPrevPos += adjustment;
              newPos += adjustment;
            }

            valuePositionOverrides[i - 1] = newPrevPos;
            valuePositionOverrides[i] = newPos;
          }
        }
      }
    }

    this.valuePositionOverrides = valuePositionOverrides;
  }

  connectedCallback() {
    super.connectedCallback();

    const { min, max, lower, upper } = this;
    for (const value of [ lower ?? min, upper ?? max]) {
      this.values.push(value);
      this.positions.push((value / (max - min)) * 100);
    }
  }

  firstUpdated() {
    super.firstUpdated();

    const {
      min,
      max,
      lower,
      upper,
      margin,
      connect,
    } = this;

    noUiSlider.create(this.#slider, {
      start: [ lower ?? min, upper ?? max ],
      range: { min, max },
      connect,
      margin,
    });

    this.#slider.noUiSlider.on('update', (values, handle, unencoded, tap, positions, noUiSlider) => {
      this.values = values;
      this.positions = positions;

      this.lower = parseInt(values[0], 10);
      this.upper = parseInt(values[values.length - 1], 10)

      this.dispatchEvent(new CustomEvent('update', {
        composed: true,
        detail: {
          values,
          handle,
          unencoded,
          tap,
          positions,
          noUiSlider,
        },
      }));

      this.adjustValuePositions();
    });

    this.noUiSlider = this.#slider.noUiSlider;

    this.noUiSlider.on('change', (values, handle, unencoded, tap, positions, noUiSlider) => {
      this.dispatchEvent(new CustomEvent('change', {
        composed: true,
        detail: {
          values,
          handle,
          unencoded,
          tap,
          positions,
          noUiSlider,
        },
      }))
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  formatValue(value) {
    return value;
  }

  handleValueTemplate(value, index) {
    const classes = {
      'value': true,
      'value--hyphenated': this.valuePositionOverrides?.[index] !== undefined
        && this.valuePositionOverrides?.[index + 1] !== undefined,
    };
    classes[`handle-${index}__value`] = true;

    const left = this.valuePositionOverrides?.[index] ? this.valuePositionOverrides[index] + 'px' : this.positions[index] + '%';

    return html`
      <span class="${classMap(classes)}" style="left: ${left};">${this.formatValue(value)}</span>
    `;
  }

  handleValuesTemplate() {
    return html`
      <div class="values">
        ${map(this.values, (value, index) => this.handleValueTemplate(value, index))}
      </div>
    `;
  }

  render() {
    return html`
      ${when(this.valuePosition === 'above-handles', () => this.handleValuesTemplate())}
      <div class="slider"></div>
    `;
  }
};

customElements.define('mv-slider', Slider);

const noUiSliderCss = `
`;
