import { getOpenEventId } from '../../util/offers';
import { getEventId } from '../../util/offers';
import { getCurrency, getPriceCatalogue, getPriceField, formatPrice } from '../../util/currency';
import { getLocale } from '../../util/locale';

export const facetPersonalisable = 'personalisable';
export const facetPersonalisableConstructorValue = 'Yes';

export const facetFlashSale = 'flash-sale';
export const facetFlashSaleConstructorValue = 'Yes';


export const facetType = 'type';
export const facetSubcategory = 'subcategory';

export const facetMap = [
  {
    name: 'stone',
    urlname: 'by-stone',
    filterContentClasses: {
      'filter__content--full-width': true,
    },
    filterGroupClasses: {
      'filter-group--d': true,
    },
    optionDisplayMode: 'swatch',
  },
  {
    name: 'metal',
    urlname: 'by-metal',
    legacyUrlnames: ['by-material'],
    filterContentClasses: {},
    filterGroupClasses: {
      'filter-group--a': true,
    },
    optionDisplayMode: 'swatch',
  },
  {
    name: 'colour',
    urlname: 'by-colour',
    filterContentClasses: {
      'filter__content--full-width': true,
    },
    filterGroupClasses: {
      'filter-group--e': true,
    },
    optionDisplayMode: 'swatch',
  },
  {
    name: 'collection',
    urlname: 'by-collection',
    legacyUrlnames: ['in-collection'],
    filterContentClasses: {
      'filter__content--full-width': true,
    },
    filterGroupClasses: {
      'filter-group--c': true,
    },
    optionDisplayMode: 'box',
  },
  {
    // The edit facet is never displayed, it is only used as the base filter for
    // edit collection listings.
    name: 'edit',
    urlname: 'by-collection',
    legacyUrlnames: ['in-collection'],
  },
  {
    name: 'type',
    urlname: 'by-type',
    browseFilterName: 'group_id',
    filterContentClasses: {},
    filterGroupClasses: {
      'filter-group--b': true,
    },
  },
  {
    name: 'subcategory',
    urlname: 'by-subcategory',
    filterContentClasses: {},
    filterGroupClasses: {
      'filter-group--b': true,
    },
  },
  {
    name: 'alphabet',
    urlname: 'by-alphabet',
    filterContentClasses: {
      'filter__content--full-width': true,
    },
    filterGroupClasses: {
      'filter-group--f': true,
    },
  },
  {
    name: 'personalisable',
    queryParam: 'personalised-only',
    transformToConstructor: (value) => value === '1' ? 'Yes' : undefined,
    transformFromConstructor: (value) => value === 'Yes' ? '1' : undefined,
  },
];

if (window?.mv?.plp?.showStockFilter) {
  facetMap.push({
    name: 'in-stock',
    queryParam: 'in-stock',
    transformToConstructor: (value) => value === '1' ? 'Yes' : undefined,
    transformFromConstructor: (value) => value === 'Yes' ? '1' : undefined,
    filterGroupClasses: {
      'filter-group--g': true,
    },
  });
}

if (window?.mv?.plp?.showPriceFilter) {
  const maxes = {
    'GBP': 1000, // British Pound
    'EUR': 1000, // Euro
    'USD': 1500, // US Dollar
    'AUD': 2000, // Australian Dollar
    'CAD': 2000, // Canadian Dollar
    'SGD': 2000, // Singapore Dollar
    'AED': 5000, // UAE
    'CNY': 10000, // Chinese Yuan
    'HKD': 10000, // Hong Kong Dollar
  }
    let sliderMax = maxes[getCurrency()];
  facetMap.push({
    name: getPriceField(),
    queryParam: 'price',
    filterGroupClasses: {
      'filter-group--s': true,
    },
    sliderMax: sliderMax,
    formatValue: (value) => {
      value = parseInt(value, 10);
      let price = formatPrice({ price: value, locale: getLocale(), currency: getCurrency() });
      // same or greater than sliderMax
      if (value >= sliderMax) {
        price += '+';
      }
      return price;
    }
  });
}

if (window?.mv?.plp?.showTraceabilityFilter) {
  facetMap.push({
    name: 'traceable',
    queryParam: 'traceable',
    transformToConstructor: (value) => value === '1' ? 'Yes' : undefined,
    transformFromConstructor: (value) => value === 'Yes' ? '1' : undefined,
    filterGroupClasses: {
      'filter-group--g': true,
    },
  });
}

if (window?.mv?.plp?.showFlashSaleFilter && getOpenEventId())
{
  facetMap.push({
    name: 'flash-sale',
    queryParam: 'flash-sale',
    transformToConstructor: (value) => value === '1' ? 'Yes' : undefined,
    transformFromConstructor: (value) => value === 'Yes' ? '1' : undefined,
  });
}

if (window?.mv?.plp?.showDiscountPercentageFilter && getEventId())
{
  facetMap.push({
    name: `event_discount_percentage_${getCurrency().toLowerCase()}_${getPriceCatalogue()}`,
    queryParam: 'discount',
    delimiter: '~',
    filterGroupClasses: {
      'filter-group--b': true,
    },
  });
}

if (window?.mv?.plp?.showRingSizeFilter) {
  facetMap.push({
    name: 'ring_size',
    queryParam: 'ring-size',
    delimiter: '~',
    filterGroupClasses: {
      'filter-group--b': true,
    },
    filterGroupClassesMobile: {
      'filter-group--e': true,
    },
  });
}

export const findMappedFacet = (facetName) => facetMap.find(({ name }) => name === facetName);

// for "options" range selectors, the data object will be empty, but they will have a range
const isOptionValid = ({ range, data }) => Array.isArray(range) || typeof data === 'object' && data !== null && Object.keys(data).length > 0;

export const isOptionSelectable = ({ count, range, data }) => (isOptionValid({ range, data }) && count > 0);

export const isFacetOptionSelected = ({ options, status }) => {
    return options && options.some(({ optionStatus }) => optionStatus === 'selected') || 
        (status && (status.hasOwnProperty('min') || status.hasOwnProperty('max')));
};

const isFacetKnown = ({ name }) => facetMap.some((facet) => facet.name === name);

const isFacetSelectable = ({ type, options }) => type === 'range' || options.filter(isOptionSelectable).length > 0;

export const getBrowseFilterName = (facet) => facet.browseFilterName ?? facet.name;

export const groupFacets = ({ result, facetState, showAllFacets, isTabletOrSmaller }) => {
  const { browse_filter_name, browse_filter_value } = result?.request ?? {};

  const facets = result?.response?.facets ?? [];

  const initialFacetCount = isTabletOrSmaller ? 1 : 2;

  const isShopAll = browse_filter_name === 'group_id' && browse_filter_value === 'all';

  // Only show supported facets that have more than one option, or have a selected option.
  const visibleFacets = facets.filter((facet) => (
    isFacetKnown(facet)
    && isFacetSelectable(facet)
    && facetState[facet.name]?.fixed !== true
    && (!browse_filter_name || isShopAll || getBrowseFilterName(findMappedFacet(facet.name) ?? {}) !== browse_filter_name)
  ));

  const headerFacets = visibleFacets.filter(({ name }) => ![facetPersonalisable, facetFlashSale].includes(name));
  const personalisationFacet = visibleFacets.find(({ name }) => name === facetPersonalisable);
  const flashSaleFacet = visibleFacets.find(({ name }) => name === facetFlashSale);

  if (isTabletOrSmaller) {
    return {
      headerFacets,
      collapseHeaderFacets: true,
      numHeaderFacetsVisible: 1,
      personalisationFacet,
      flashSaleFacet,
    };
  }

  const collapseHeaderFacets = (
    headerFacets.length > initialFacetCount
    && !showAllFacets
    && !headerFacets.slice(initialFacetCount).some(({ status, options }) =>
      (status && ('min' in status || 'max' in status)) || (options && options.some(({ status }) => status === 'selected')))
  );

  return {
    headerFacets,
    collapseHeaderFacets,
    numHeaderFacetsVisible: collapseHeaderFacets ? initialFacetCount : headerFacets.length,
    personalisationFacet,
    flashSaleFacet,
  };
}

export const determineRequiredHiddenFacets = (facetValues, browseFilterName = undefined, browseFilterValue = undefined) => {
  const hiddenFacets = new Set();

  for (const facet in facetValues) {
    if (facet === facetType || facet === facetSubcategory) {
      hiddenFacets.add(facetSubcategory);
    }
  }

  if (browseFilterName === 'group_id' && browseFilterValue !== 'all') {
    hiddenFacets.add(facetSubcategory);
  }

  return [...hiddenFacets.values()];
}
