import { getLocalePathPrefix } from '../../util/locale';
import { facetMap, getBrowseFilterName } from './facets';

const sortByMap = [
  {
    urlname: undefined,
    sortBy: 'relevance',
    sortOrder: 'ascending',
  },
  {
    urlname: 'new-in',
    sortBy: 'launch-date',
    sortOrder: 'descending',
  },
  {
    urlname: 'price-asc',
    sortBy: 'price',
    sortOrder: 'ascending',
  },
  {
    urlname: 'price-desc',
    sortBy: 'price',
    sortOrder: 'descending',
  },
];

/**
 * ListingURL is a parsed URL for a PLP or search page.
 */
export default class ListingURL {
  #pathPrefix;

  #query;

  #facets;

  #sortBy;

  #sortOrder;

  #page;

  #browseFilterName;

  #browseFilterValue;

  #fixedFacet;

  constructor({
    pathPrefix,
    browseFilterName,
    browseFilterValue,
    query,
    facets = {},
    fixedFacet,
    sortBy,
    sortOrder,
    page = 1,
  }) {
    this.#pathPrefix = pathPrefix;
    this.#browseFilterName = browseFilterName;
    this.#browseFilterValue = browseFilterValue;
    this.#query = query;
    this.#sortBy = sortBy;
    this.#sortOrder = sortOrder;
    this.#page = page;

    this.#facets = [];
    for (const facet in facets) {
      this.setFacetValues(facet, facets[facet]);
    }

    this.#fixedFacet = fixedFacet;
  }

  static parse(url) {
    const params = url.searchParams;

    let facets = {};

    let pathParts = url.pathname.split('/').slice(1);
    let offset = 0;

    // Ignore trailing slash.
    if (pathParts[pathParts.length - 1] === '') {
      pathParts = pathParts.slice(0, -1);
    }

    let pathPrefix = '';
    const localePathPrefix = getLocalePathPrefix();
    if (localePathPrefix) {
      pathPrefix += localePathPrefix;

      if (pathParts[offset] === localePathPrefix.slice(1)) {
        offset++;
      }
    }

    const prefixAppend = (n = 1) => {
      for (; n > 0; n--) {
        pathPrefix += `/${pathParts[offset]}`;
        offset++;
      }
    };

    if (pathParts[offset] === 'search' || pathParts[offset] === 'shop') {
      prefixAppend();
    }

    const { filterName: browseFilterName, filterValue: browseFilterValue } = window.mv?.plp ?? {};

    // If the browse filter has been specified in the URL, that forms part of
    // the prefix. It is not treated the same as other facets encoded in the
    // path and it must not be removed if the facet values are cleared.
    if (browseFilterName) {
      const browseFilter = facetMap.find((facet) => browseFilterName === getBrowseFilterName(facet));
      const browseFilterUrlnames = [];
      if (browseFilter) {
        browseFilterUrlnames.push(browseFilter.urlname);
        browseFilterUrlnames.push(...browseFilter.legacyUrlnames ?? []);
      }

      if (offset < pathParts.length && pathParts[offset] === browseFilterValue) {
        prefixAppend();
      } else if (
        offset + 1 < pathParts.length
        && browseFilterUrlnames.includes(pathParts[offset])
        && pathParts[offset+1] === browseFilterValue
      ) {
        prefixAppend(2);
      }
    }

    // Parse facets encoded in the path.
    for (; offset < pathParts.length - 1; offset += 2) {
      const facetPart = pathParts[offset];
      const valuePart = pathParts[offset + 1];

      const { name: facet, transformToConstructor } = facetMap.find(({ urlname, legacyUrlnames }) => urlname === facetPart || legacyUrlnames?.includes(facetPart)) ?? {};
      if (facet) {
        let values = valuePart.split('~');
        if (transformToConstructor) {
          values = values.map(transformToConstructor);
        }

        facets[facet] = values;
      }
    }

    // Parse facets encoded in query parameters.
    for (const { name, queryParam, transformToConstructor, delimiter } of facetMap) {
      if (queryParam && params.has(queryParam)) {
        let values = [params.get(queryParam)];
        if (transformToConstructor) {
          values = values.map(transformToConstructor);
        }

        if (delimiter) {
          values = values.map(value => value.split(delimiter)).flat(Infinity);
        }

        facets[name] = values;
      }
    }

    const fixedFacet = params.get('fixed');

    // Parse sortBy and sortOrder, which may be encoded in the path.
    let sortBy, sortOrder;
    if (pathParts.length >= 2 && pathParts[pathParts.length - 2] === 'sort-by') {
      const sortByRaw = pathParts[pathParts.length - 1];

      const mappedSortBy = sortByMap.find(({ urlname }) => urlname === sortByRaw) ?? {};
      sortBy = mappedSortBy.sortBy;
      sortOrder = mappedSortBy.sortOrder;
    }

    // Parse the page number, which may be encoded in the query string.
    let page = params.has('page') ? parseInt(params.get('page'), 10) : 1;
    if (isNaN(page) || page < 1) {
      page = 1;
    }

    return new ListingURL({
      pathPrefix,
      browseFilterName,
      browseFilterValue,
      query: params.get('q'),
      facets,
      fixedFacet: fixedFacet ? fixedFacet : undefined,
      sortBy,
      sortOrder,
      page,
    });
  }

  build() {
    const url = new URL(window.location.href);
    url.search = '';
    url.fragment = '';

    const pathParts = [];

    if (typeof this.#query === 'string')  {
      url.searchParams.set('q', this.#query ? this.#query : undefined);
    }

    for (const facet of this.#facets) {
      const { urlname, queryParam, transformFromConstructor, delimiter } = facetMap.find(({ name }) => name === facet.name) ?? {};

      let values = facet.values;
      if (transformFromConstructor) {
        values = values.map(transformFromConstructor);
      }

      if (urlname) {
        pathParts.push(urlname, values.join('~'));
      } else if (queryParam) {
        if (delimiter) {
          url.searchParams.set(queryParam, values.join(delimiter));
        } else {
          url.searchParams.set(queryParam, values[0]);
        }
      }
    }

    if (this.#fixedFacet) {
      url.searchParams.set('fixed', this.#fixedFacet);
    }

    // TODO update sort-by logic in https://d3rjira.atlassian.net/browse/MV-11596
    const { urlname: sortByUrlname } = sortByMap.find(({ sortBy: sortByMapped, sortOrder: sortOrderMapped }) => this.#sortBy === sortByMapped && this.#sortOrder === sortOrderMapped) ?? {};
    if (sortByUrlname) {
      pathParts.push('sort-by', sortByUrlname);
    }

    url.pathname = [this.#pathPrefix, ...pathParts.map((part) => encodeURIComponent(part))].join('/');

    if (typeof this.#page === 'number' && this.#page !== 1) {
      url.searchParams.set('page', this.#page);
    }

    return url;
  }

  get query() {
    return this.#query;
  }

  get browseFilter() {
    return {
      name: this.#browseFilterName,
      value: this.#browseFilterValue,
    };
  }

  get page() {
    return this.#page;
  }

  setPage(page) {
    this.#page = page;
  }

  get sortBy() {
    return this.#sortBy;
  }

  get sortOrder() {
    return this.#sortOrder;
  }

  setSort(sortBy, sortOrder) {
    this.#sortBy = sortBy;
    this.#sortOrder = sortOrder;
  }

  get facets() {
    return this.#facets;
  }

  get fixedFacet() {
    return this.#fixedFacet;
  }

  toggleFacetValue(name, value, selected) {
    const { values } = this.#facets.find((facet) => facet.name === name) ?? { values: [] };

    if (selected && !values.includes(value)) {
      values.push(value);
      this.setFacetValues(name, values);
    } else if (!selected) {
      this.setFacetValues(name, values.filter((v) => v !== value));
    }
  }

  setFacetValues(name, values) {
    const facetIdx = this.#facets.findIndex((facet) => facet.name === name);

    if (values?.length) {
      values.sort();

      if (facetIdx === -1) {
        this.#facets.push({ name, values });

        // TODO review value sort logic
        this.#facets.sort((a, b) => {
          const aPos = facetMap.findIndex(({ name }) => name === a);
          const bPos = facetMap.findIndex(({ name }) => name === b);

          if (aPos === -1 && bPos !== -1) {
            return 1;
          } else if (aPos !== -1 && bPos === -1) {
            return -1;
          } else if (aPos === -1 && bPos === -1) {
            if (a < b) {
              return -1;
            } else if (a > b) {
              return 1;
            }
            return 0;
          } else {
            return aPos - bPos;
          }
        });
      } else {
        this.#facets[facetIdx].values = values;
      }
    } else if (facetIdx !== -1) {
      this.#facets.splice(facetIdx, 1);
    }
  }
}
