import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getConstructorIOClient, sectionProducts, filterSortOptions, translateSortOption} from '../../constructor/client';
import { fixSiteURL } from '../../util/url';
import { determineRequiredHiddenFacets, groupFacets } from '../plp/facets';
import ListingURL from '../plp/url';
import { initialListingState, listingReducers } from '../plp/state';
import { dataLayerPush } from '../tracking';
import { Pagination } from '../../util/pagination.mjs';
import { getEventId } from '../../util/offers';
import { getCurrency, getPriceCatalogue } from '../../util/currency';

/**
 * Redux slice for the search page.
 */
const searchSlice = createSlice({
  name: 'search',
  initialState: {
    ...initialListingState(),
    query: ListingURL.parse(new URL(window.location.href)).query,
  },
  reducers: {
    ...listingReducers,
    beginSearch(state, action) {
      const { query, page } = action.payload;
      state.query = query;
      state.page = page;
      state.loading = true;
      state.requestId += 1;
    },
  },
});

export default searchSlice.reducer;

export const {
  toggleFacetVisibility,
  toggleShowAllFacets,
  selectVariation,
  toggleSortVisibility,
  updateBreakpointMatches,
} = searchSlice.actions;

const {
  beginSearch,
  resultsLoaded,
} = searchSlice.actions;

export const search = createAsyncThunk(
  'search/search',
  async ({ query, facets, sortBy, sortOrder, page }, { dispatch, getState, signal }) => {
    if (!query) {
      window.location = fixSiteURL('/shop');
      return;
    }

    dispatch(beginSearch({ query, page }));
    const { pageAdjustments, resultsPerPage, requestId } = getState().search;

    const pagination = new Pagination(resultsPerPage);
    pagination.setAdjustments(pageAdjustments);
    pagination.setPage(page);

    try {
      const constructorio = await getConstructorIOClient();

      const params = {
        section: sectionProducts,
        filters: facets,
        sortBy: translateSortOption(sortBy),
        sortOrder,
        offset: pagination.currentOffset,
        resultsPerPage: pagination.currentPageSize,
        hiddenFacets: determineRequiredHiddenFacets(facets),
      };

      if (getEventId()) {
        params.hiddenFields = ['event_offers_' + getCurrency().toLowerCase() + '_' + getPriceCatalogue()];
      }

      const result = await constructorio.search.getSearchResults(query, params);
      result.response.sort_options = filterSortOptions(result.response.sort_options);

      if (result?.request?.feature_variants) {
        dataLayerPush({
            constructorFeatureVariants: result.request.feature_variants
        });
      }

      // If the customer has not previously chosen to show all facets and the
      // results have been filtered by a facet that would otherwise be hidden,
      // e.g. on initial page load, force all facets to be visible.
      const { breakpointMatches, facets: facetState, showAllFacets } = getState().search;
      const { tablet: isTabletOrSmaller } = breakpointMatches;
      if (!showAllFacets && !isTabletOrSmaller) {
        const { collapseHeaderFacets } = groupFacets({
          result,
          facetState,
          isTabletOrSmaller,
        });

        if (!collapseHeaderFacets) {
          dispatch(toggleShowAllFacets({ show: true }));
        }
      }

      dispatch(resultsLoaded({
        requestId,
        result,
      }));
    } catch (e) {
      // TODO handle error https://d3rjira.atlassian.net/browse/MV-11547
      console.error('search failed', e);
    }
  },
);
