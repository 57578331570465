export class Pagination {
  #pageSize;
  #numLinks = 4;
  #offset = 0;
  #itemCount = 0;
  #adjustments = [];

  constructor(pageSize) {
    this.#pageSize = pageSize;
  }

  setPage(page) {
    let offset = 0;
    for (let i = 0; i < this.#adjustments.length && i < page - 1; i++) {
      offset += this.#adjustments[i];
    }

    for (let i = this.#adjustments.length; i < page - 1; i++) {
      offset += this.#pageSize;
    }

    this.#offset = offset;
  }

  setOffset(offset) {
    this.#offset = offset;
  }

  setAdjustments(adjustments) {
    this.#adjustments = adjustments;
  }

  setItemCount(count) {
    this.#itemCount = count;
  }

  get currentOffset() {
    return this.#offset;
  }

  get currentPage() {
    return this.#offsetPageNumber(this.#offset);
  }

  get lastPage() {
    return this.#offsetPageNumber(this.#itemCount - 1);
  }

  get currentPageSize() {
    return this.#adjustments[this.currentPage - 1] ?? this.#pageSize;
  }

  #offsetPageNumber(offset) {
    let page = 1;
    let remaining = offset;

    while (page <= this.#adjustments.length && remaining > 0) {
      const pageSize = this.#adjustments[page - 1];

      if (remaining < pageSize) {
        break;
      }

      remaining -= pageSize;
      page++;
    }

    if (remaining > 0) {
      page += Math.floor(remaining / this.#pageSize);
    }

    return page;
  }

  get links() {
    const lastPage = this.lastPage;
    if (lastPage <= 1) {
      return [];
    }

    const currentPage = this.currentPage;

    const firstNumericLink = Math.max(Math.min(currentPage - 1, lastPage - this.#numLinks + 1), 1);
    const lastNumericLink = Math.min(firstNumericLink + this.#numLinks - 1, lastPage);

    const links = [];

    if (currentPage > 1) {
      links.push({
        label: 'First',
        page: 1,
        cta: true,
      });
    }

    links.push({
      label: 'Prev',
      page: Math.max(currentPage - 1, 1),
      cta: true,
      disabled: currentPage < 2,
    });

    for (let i = firstNumericLink; i <= lastNumericLink; i++) {
      links.push({
        label: i.toString(),
        page: i,
        active: i === currentPage,
      });
    }

    links.push({
      label: 'Next',
      page: Math.min(currentPage + 1, lastPage),
      cta: true,
      disabled: currentPage >= lastPage,
    });

    if (currentPage < lastPage) {
      links.push({
        label: 'Last',
        page: lastPage,
        cta: true,
      });
    }

    return links;
  }
}
