import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getConstructorIOClient, sectionProducts, filterSortOptions, translateSortOption } from '../../constructor/client';
import { determineRequiredHiddenFacets, groupFacets } from './facets';
import { isBreakpointMatch } from '../../util/viewport';
import { Pagination } from '../../util/pagination.mjs';
import { dataLayerPush } from '../tracking';
import { trackProductListImpressions } from '../tracking/list';
import { getCurrency, getPriceCatalogue } from '../../util/currency';
import { convertConstructorResultToDatalayer } from './listing';
import { getEventId } from '../../util/offers';

const initialCalloutsState = (resultsPerPage) => {
  const callouts = window.mv?.plp?.callouts ?? [];

  return {
    callouts,
    pageAdjustments: calculatePageAdjustments(callouts, resultsPerPage),
  };
}

const calculatePageAdjustments = (callouts, resultsPerPage) => {
  const pageResultCounts = [];
  for (const { position, width, height } of callouts) {
    if (position === 'lc') {
      continue;
    }

    const page = Math.floor(position / resultsPerPage);
    while (page >= pageResultCounts.length) {
      pageResultCounts.push(resultsPerPage);
    }

    const size = width * height;
    pageResultCounts[page] -= size;
  }

  return pageResultCounts;
};

/**
 * Get the initial state common to all listing pages (search and browse).
 */
export const maxResultsPerPage = 24;
export const initialListingState = () => ({
  page: 1,
  resultsPerPage: maxResultsPerPage,
  loading: true,
  requestId: 0,
  sort: {
    open: false,
  },
  facets: {},
  showAllFacets: false,
  ...initialCalloutsState(maxResultsPerPage),
  breakpointMatches: {
    tablet: isBreakpointMatch('tablet'),
  },
});

/**
 * Reducers common to all listing pages (search and browse).
 */
export const listingReducers = {
  resultsLoaded(state, action) {
    const { requestId, result } = action.payload;

    // If we received results out of order, ignore them.
    if (requestId !== state.requestId) {
      return;
    }

    if (result && result?.response?.results) {
      trackProductListImpressions({
        list: window.location.pathname,
        canonicalListUrl: window.mv?.plp?.canonicalListingUrl ?? '/shop',
        currency: getCurrency(),
        items: convertConstructorResultToDatalayer(result),
      });
    }

    state.result = result;
    state.selectedVariations = {};
    state.loading = false;

    const resultFacetNames = result.response?.facets?.map(({ name }) => name) ?? [];

    for (const facet in state.facets) {
      if (!resultFacetNames.includes(facet)) {
        delete state.facets[facet];
      }
    }
  },
  selectVariation(state, action) {
    const { item, variation } = action.payload;

    state.selectedVariations[item] = variation;
  },
  toggleSortVisibility(state, action) {
    state.sort.open = action.open ?? !state.sort.open;
  },
  toggleFacetVisibility(state, action) {
    const { facet, open } = action.payload;

    const wasOpen = state.facets[facet]?.open ?? false;
    state.facets[facet] = Object.assign({}, state.facets[facet], {
      open: open ?? !wasOpen,
    });

    // Close all the other facets
    if (state.facets[facet].open) {
      for (const otherFacet in state.facets) {
        if (otherFacet !== facet) {
          state.facets[otherFacet].open = false;
        }
      }
    }
  },
  toggleShowAllFacets(state, action) {
    const { show } = action.payload;

    const wasShowingAll = state.showAllFacets;
    state.showAllFacets = show ?? !wasShowingAll;
  },
  updateBreakpointMatches(state, action) {
    const tabletChanged = state.breakpointMatches.tablet !== action.payload.tablet;
    state.breakpointMatches = action.payload;

    if (tabletChanged) {
      state.showAllFacets = false;
    }
  },
};

/**
 * Redux slice for product listing pages (PLPs).
 */
const plpSlice = createSlice({
  name: 'plp',
  initialState: {
    ...initialListingState(),
  },
  reducers: {
    ...listingReducers,

    beginLoadingResults(state, action) {
      const { fixedFacet, page } = action.payload;

      if (fixedFacet) {
        state.facets[fixedFacet] = Object.assign({}, state.facets[fixedFacet], {
          fixed: true,
        });
      }

      for (const facet in state.facets) {
        state.facets[facet].fixed = facet === fixedFacet;
      }

      state.page = page;
      state.loading = true;
      state.requestId += 1;
    },
  },
});

export default plpSlice.reducer;

export const {
  toggleFacetVisibility,
  toggleShowAllFacets,
  selectVariation,
  toggleSortVisibility,
  updateBreakpointMatches,
} = plpSlice.actions;

const {
  beginLoadingResults,
  resultsLoaded,
} = plpSlice.actions;

export const browse = createAsyncThunk(
  'plp/browse',
  async ({ filterName, filterValue, facets, fixedFacet, sortBy, sortOrder, page }, { dispatch, getState, signal }) => {
    dispatch(beginLoadingResults({ fixedFacet, page }));
    const { pageAdjustments, resultsPerPage, requestId } = getState().plp;

    const pagination = new Pagination(resultsPerPage);
    pagination.setAdjustments(pageAdjustments);
    pagination.setPage(page);

    try {
      const constructorio = await getConstructorIOClient();

      const params = {
        section: sectionProducts,
        filters: facets,
        sortBy: translateSortOption(sortBy),
        sortOrder,
        offset: pagination.currentOffset,
        resultsPerPage: pagination.currentPageSize,
        hiddenFacets: determineRequiredHiddenFacets(facets, filterName, filterValue),
        hiddenFields: ['set_price_' + getCurrency().toLowerCase() + '_' + getPriceCatalogue()]
      };

      if (getEventId()) {
        params.hiddenFields.push('event_offers_' + getCurrency().toLowerCase() + '_' + getPriceCatalogue());
      }

      const result = await constructorio.browse.getBrowseResults(filterName, filterValue, params);
      result.response.sort_options = filterSortOptions(result.response.sort_options);

      if (result?.request?.feature_variants) {
        dataLayerPush({
            constructorFeatureVariants: result.request.feature_variants
        });
      }

      // If the customer has not previously chosen to show all facets and the
      // results have been filtered by a facet that would otherwise be hidden,
      // e.g. on initial page load, force all facets to be visible.
      const { breakpointMatches, facets: facetState, showAllFacets } = getState().plp;
      const { tablet: isTabletOrSmaller } = breakpointMatches;
      if (!showAllFacets && !isTabletOrSmaller) {
        const { collapseHeaderFacets } = groupFacets({
          result,
          facetState,
          isTabletOrSmaller,
        });

        if (!collapseHeaderFacets) {
          dispatch(toggleShowAllFacets({ show: true }));
        }
      }

      dispatch(resultsLoaded({
        requestId,
        result,
      }));
    } catch (e) {
      // TODO handle error https://d3rjira.atlassian.net/browse/MV-11547
      console.error('failed to load plp results', e);
    }
  },
);
