import { getCurrency, getPriceCatalogue } from '../util/currency';
import { getCustomerId } from '../util/customer';
import { getEventId } from '../util/offers';
import { getDeliveryCountry, getLocale } from '../util/locale';
import ConstructorioClient from '@constructor-io/constructorio-client-javascript';

export const sectionProducts = 'Products';
export const sectionSearchSuggestions = 'Search Suggestions';

const getConstructorIndex = (index) => window?.mv?.constructor?.indexes?.[index]?.key;

const mustGetConstructorIndex = (index) => {
  const indexKey = getConstructorIndex(index);
  if (!indexKey) {
    throw new Error(`Constructor index key not set for ${index}`);
  }

  return indexKey;
};

const getSortPriceField = () => {
  return (getEventId() ? 'event_' : '') + 'price_' + getCurrency().toLowerCase() + '_' + getPriceCatalogue();
};

export const filterSortOptions = (options) => {
    if (!options) {
        return options;
    }

    const priceField = getSortPriceField();
    return options.filter(
        (option) => !option.sort_by.includes('price') || option.sort_by == priceField
    ).map(
        (option) => ({ ...option, sort_by: option.sort_by.replace(priceField, 'price') })
    );
};

export const translateSortOption = (option) => {
    if (!option) {
        return option;
    }

    const priceField = getSortPriceField();
    return option.replace('price', priceField);
}

let client = null;
export const getConstructorIOClient = async () => {
  if (client === null) {
    let options = {
      apiKey: mustGetConstructorIndex('default'),
      segments: [],
    };

    const customerId = getCustomerId();
    if (customerId) {
      options.userId = customerId;
    }

    const locale = getLocale();
    if (locale) {
      options.segments.push(`locale:${locale}`);
    }

    const deliveryCountry = getDeliveryCountry();
    if (deliveryCountry) {
      options.segments.push(`delivery_country:${deliveryCountry}`);
    }

    client = new ConstructorioClient(options);
  }

  return client;
};
