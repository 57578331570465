import { dataLayerPush, trackNavigation } from '../tracking';

/**
 * Track a click on an autosuggest result.
 *
 * @param {PointerEvent} e
 * @returns {void}
 */
export const trackAutosuggestResultClick = (e) => {
  e.preventDefault();

  const url = e.target.closest('a').href;

  trackNavigation(url, {
    event: 'autosuggest_result_click',
  });
};

/**
 * Track a click on an autosuggest popular search result.
 *
 * These results are displayed when the user hasn't entered a search query yet.
 *
 * @param {PointerEvent} e
 * @returns {void}
 */
export const trackAutosuggestPopularClick = (e) => {
  e.preventDefault();

  const url = e.target.closest('a').href;

  trackNavigation(url, {
    event: 'autosuggest_popular_click',
  });
};

let searched = false;

/**
 * Track an autosuggest search.
 *
 * Only the first search is tracked.
 */
export const trackAutosuggestSearch = ({ query }) => {
  if (searched || !query) {
    return;
  }

  searched = true;

  dataLayerPush({
    event: 'autosuggest_search',
  });
};
