import breakpoints from '../../config/breakpoints.json';

const matchers = {};

const getMatcher = (breakpoint) => {
  if (!(breakpoint in matchers)) {
    const options = breakpoints['media-queries'][breakpoint] ?? null;
    if (!options) {
      throw new Error(`Unknown breakpoint ${breakpoint}`);
    }

    const { size, type } = options

    const mediaQuery = `(${type}: ${size}px)`;

    matchers[breakpoint] = window.matchMedia(mediaQuery)
  }

  return matchers[breakpoint];
}

/**
 * Monitor a media query for changes
 *
 * callback is called immediately with the current state of the media query and
 * then every time the media query state changes.
 *
 * @param {string} breakpoint
 * @param {*} callback
 */
export const monitorBreakpoint = (breakpoint, callback) => {
  const matcher = getMatcher(breakpoint);

  matcher.addEventListener('change', (e) => { callback({ matches: e.matches })});
  callback({ matches: matcher.matches });
};

export const isBreakpointMatch = (breakpoint) => getMatcher(breakpoint).matches;
