import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getConstructorIOClient, sectionProducts, sectionSearchSuggestions } from '../../constructor/client';
import { trackAutosuggestSearch } from './tracking';
import { dataLayerPush } from '../tracking';

const autosuggestSlice = createSlice({
  name: 'autosuggest',
  initialState: {
    query: '',
    loadingResults: false,
  },
  reducers: {
    beginSearch(state, action) {
      const { query, simple } = action.payload;
      state.query = query;
      state.simple = simple;
      state.loadingResults = true;
    },
    resultsLoaded(state, action) {
      const { query, results } = action.payload;

      // If we received results out of order, ignore them.
      if (query !== state.query) {
        return;
      }

      state.results = results;
      state.selectedVariations = {};
      state.loadingResults = false;
    },
    loadedExplore(state, action) {
      state.exploreContent = action.payload;
    },
    loadedPopularSearches(state, action) {
      state.popularSearches = action.payload;
    },
    selectVariation(state, action) {
      const { item, variation } = action.payload;
      state.selectedVariations[item] = variation;
    },
  },
});

export default autosuggestSlice.reducer;

export const { selectVariation } = autosuggestSlice.actions;

const {
  beginSearch,
  resultsLoaded,
  loadedExplore,
  loadedPopularSearches,
} = autosuggestSlice.actions;

const fetchExplore = async ({ signal }) => {
  const response = await fetch('/search/explore', {
    signal,
  });
  return response.json();
};

const fetchPopularSearches = async ({ signal }) => {
  const response = await fetch('/search/popular', {
    signal,
  });
  const data = await response.json();
  return data.payload;
};

export const search = createAsyncThunk(
  'autosuggest/search',
  async ({ query, simple }, { dispatch, getState, signal }) => {
    dispatch(beginSearch({ query, simple }));
    trackAutosuggestSearch({ query });

    let results;
    if (query) {
      try {
        const constructorio = await getConstructorIOClient();

        results = await constructorio.autocomplete.getAutocompleteResults(query, {
          resultsPerSection: {
            [sectionSearchSuggestions]: simple ? 8 : 10,
            [sectionProducts]: simple ? 0 : 24,
          },
        });

        if (results?.request?.feature_variants) {
            dataLayerPush({
                event: 'constructorAutosuggestResults',
                constructorFeatureVariants: results.request.feature_variants
            });
        }
      } catch (e) {
        console.error('search failed', e);
      }
    }

    const searchSuggestionsReturned = !!results?.sections?.[sectionSearchSuggestions]?.length;
    if (!searchSuggestionsReturned && !getState().autosuggest.popularSearches) {
      try {
        const popularSearches = await fetchPopularSearches({ signal });
        dispatch(loadedPopularSearches(popularSearches));
      } catch (e) {
      }
    }

    const productsReturned = !!results?.sections?.[sectionProducts]?.length;
    if (!productsReturned && !getState().autosuggest.exploreContent) {
      try {
        const exploreContent = await fetchExplore({ signal });
        dispatch(loadedExplore(exploreContent));
      } catch (e) {
      }
    }

    dispatch(resultsLoaded({ query, results }));
  },
);
